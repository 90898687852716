<template>
  <div>
    <div class="bg-white">
      <div class="bg-orange--grad guest-form-header">
        <div class="text-title --white text-center font-weight-bold pt-3 pb-3">
          {{ $t('booking.accompanyingPets') }}
        </div>
      </div>
      <!--  -->
      <div class="px-4 pb-4 mb-4">

        <!--Pet use or not BEGIN-->
        <v-radio-group
          hide-details
          v-model="hasPet"
          @change="toggleHasPet"
        >
          <v-container>
            <v-row>
              <v-col
                class="mr-1 pa-0"
                v-for="item in radio"
                :key="item.value"
              >
                <label class="radio-border d-flex pa-2">
                  <v-radio
                    class="px-0"
                    :value="item.value"
                  ></v-radio>
                  <div class="d-flex flex-column">
                    <div class="text-medium">
                      {{ $t('common.pets') }}
                    </div>
                    <h1 class="text-medium --prm">
                      {{ item.use }}
                    </h1>
                  </div>
                </label>

              </v-col>
              <v-col class="col-2 px-0 d-flex flex-column justify-center">
                <span class="text-small text-center --white px-0 bg-pink rounded-4 py-1 mx-2">
                  {{ $t('reservationAndUsageHistory.mandatory') }}
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-radio-group>
        <!--Pet use or not END-->
        <template v-if="hasPet">
          <!--Number of cars header BEGIN-->
          <div class="text-medium pt-6 pb-3">
            {{ $t('booking.petsNumber') }}
            <span class="text-small  col-2 text-center --white px-0 bg-pink rounded-4 py-1 px-2">
                {{ $t('reservationAndUsageHistory.mandatory') }}
            </span>
          </div>
          <v-container>
            <v-row >
              <v-col class="text-medium  col-5 --c-bg px-0 py-1">
                <v-select
                  hide-details
                  solo
                  flat
                  outlined
                  class="rounded-lg"
                  :items="petCountList"
                  v-model="petCount"
                ></v-select>
              </v-col>
              <v-col></v-col>
            </v-row>
          </v-container>
          <!--Number of cars END-->
          <FormLabel :label="$t('booking.breeds')" />
          <div>
            <v-text-field
              v-model="breed"
              solo
              flat
              outlined
              class="rounded-lg"
              hide-details
            />
          </div>
          <div class="d-flex">
            <v-checkbox v-model="vaccinated" hide-details>
              <template v-slot:label>
                <span class="text-medium mr-2 --bg">{{ $t('booking.allPetsAreVaccinated') }}</span>
                <span class="text-small  col-2 text-center --white px-0 bg-pink rounded-4 py-1 px-2">
                    {{$t('reservationAndUsageHistory.mandatory')}}
                </span>
              </template>
            </v-checkbox>
          </div>
        </template>
      </div>
      <!--Pet lot format END-->
    </div>
  </div>
</template>

<script>
import FormLabel from '../Inputs/FormLabel'
import { range } from '@/utils/generator'
export default {
  name: 'ChangePetInformation',
  components: { FormLabel },
  props: {
    btn: {
      Boolean
    },
    value: {
      type: Object,
      default: () => {}
    },
    max: Number
  },
  mounted () {
    if (this.value === null) {
      this.hasPet = false
    } else if (this.value === undefined) {
      this.emitInput({})
    }
  },
  methods: {
    emitInput (pets) {
      this.$emit('input', pets)
    },
    toggleHasPet (hasPet) {
      this.emitInput(hasPet ? {} : null)
    }
  },
  data () {
    return {
      hasPet: true,
      radio: [
        { use: this.$t('booking.accompaniedBy'), value: true },
        { use: this.$t('booking.unaccompaniedBy'), value: false }
      ]
    }
  },
  computed: {
    petCount: {
      get () { return this.value?.petNumber ?? 0 },
      set (newNumber) {
        this.emitInput({
          ...this.value,
          petNumber: newNumber
        })
      }
    },
    petCountList () {
      return range(1, this.max)
    },
    breed: {
      get () { return this.value?.petBreed ?? '' },
      set (newBreed) {
        this.emitInput({
          ...this.value,
          petBreed: newBreed
        })
      }
    },
    vaccinated: {
      get () { return this.value?.vaccinated ?? false },
      set (newVaccinated) {
        this.emitInput({
          ...this.value,
          vaccinated: newVaccinated
        })
      }
    },
    isPetManaged () {
      return this.$store.getters.isPetManagedFacility
    }
  }
}
</script>
