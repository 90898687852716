<template>
  <div>
    <div class="bg-white">
      <div class="bg-primary guest-form-header">
        <div class="text-title --c-white text-center font-weight-bold pt-3 pb-3">
          {{ bookingPlan.title }}
        </div>
      </div>
      <div class="pa-4 mb-4">
        <h4 class="text-sub-title font-weight-regular mb-2">{{ bookingPlan.subtitle }}</h4>
        <p class="text-medium">{{ bookingPlan.remarks }}</p>
        <v-row v-if="bookingPlan.imagePublicUrl1 || bookingPlan.imagePublicUrl2" class="mt-2">
          <v-col v-if="bookingPlan.imagePublicUrl1" cols="6" class="pr-1">
            <v-img
              :src="getImage(bookingPlan.imagePublicUrl1, true)"
              :lazy-src="lazyReservationImgUrl"
              max-height="150"
            />
          </v-col>
          <v-col v-if="bookingPlan.imagePublicUrl2" cols="6" class="pl-1">
            <v-img
              :src="getImage(bookingPlan.imagePublicUrl2, true)"
              :lazy-src="lazyReservationImgUrl"
              max-height="150"
            />
          </v-col>
        </v-row>
        <div v-for="question in bookingPlanQuestions" :key="question.question" class="my-2">
          <div class="bg-smoke text-medium font-weight-bold --c-ms py-2 pl-2 mt-4 d-flex">
            {{ $t('common.question', { number: question.question }) }}
            <div v-if="question.required" class="text-small text-center --c-white bg-pink rounded-4 px-2 py-1 ml-3">
              {{ $t("reservationAndUsageHistory.mandatory") }}
            </div>
          </div>
          <p class="text-medium mt-2">
            {{ question.text }}
          </p>
          <div v-if="question.type === 'Select' && question.selects.length">
            <v-radio-group
              hide-details
              :value="value && value[question.question] && value[question.question].answer.i"
            >
              <v-radio
                v-for="select in question.selects"
                class="pa-2 radio-border"
                :value="select.i"
                :key="select.i"
                @change="onChangeAnswers(question, select.text, select.i)"
              >
                <template v-slot:label>
                  <div class="--c-x-dark">
                    {{ select.text }}
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
          </div>
          <div v-else>
            <FormLabel :label="$t('common.answer').toString()" class="mb-0"/>
            <v-text-field
              hide-details
              solo
              :value="value && value[question.question] && value[question.question].answer.text"
              maxlength="512"
              @change="v => onChangeAnswers(question, v.trim())"
            ></v-text-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { lazyImageMixin } from '@/mixins/lazy-image.mixin'
import FormLabel from '@/components/Inputs/FormLabel'

export default {
  name: 'BookingPlanInformation',
  mixins: [lazyImageMixin],
  components: { FormLabel },
  props: {
    bookingPlan: {
      type: Object,
      required: true
    },
    // for save booking plan values
    value: {
      type: Object,
      default: () => {}
    }
  },
  mounted () {
    if (!this.value) {
      const defaultQuestions = this.bookingPlanQuestions.reduce((pr, val) => ({
        ...pr,
        [val.question]: {
          required: val.required,
          answer: {
            text: ''
          },
          index: val.question
        }
      }), {})
      this.emitInput(defaultQuestions)
    }
  },
  methods: {
    emitInput (plan) {
      this.$emit('input', plan)
    },
    onChangeAnswers (question, answer, selectIndex) {
      this.emitInput({
        ...this.value,
        [question.question]: {
          required: question.required,
          answer: {
            i: selectIndex ?? 0,
            text: answer
          },
          index: question.question
        }
      })
    }
  },
  computed: {
    bookingPlanQuestions () {
      const planQuestions = []
      for (let i = 1; i <= 5; i++) {
        let text = this.bookingPlan[`question${i}Text`]
        text = text ? text.trim() : text
        const type = this.bookingPlan[`question${i}Type`]
        if (text && type) {
          const selects = []
          if (type === 'Select') {
            for (let j = 1; j <= 5; j++) {
              let select = this.bookingPlan[`question${i}Select${j}`]
              select = select ? select.trim() : select
              if (select) selects.push({ i: j, text: select })
            }
          }
          planQuestions.push({
            question: i,
            text,
            type,
            required: this.bookingPlan[`question${i}Required`],
            selects
          })
        }
      }
      return planQuestions
    }
  }
}
</script>
