<template>
  <div>
    <div class="bg-white mb-4">
      <div class="bg-orange--grad guest-form-header">
        <div class="text-title --c-white text-center font-weight-bold pt-3 pb-3">
          {{ $t('booking.accompanyingPets') }}
        </div>
      </div>
      <!--  -->
      <div class="px-4 pb-4 mb-4">

        <!--Pet use or not BEGIN-->
        <v-radio-group
          hide-details
          :value="value"
          @change="$emit('input', $event)"
        >
          <v-container>
            <v-row>
              <v-col
                class="mr-1 pa-0"
                v-for="item in radio"
                :key="item.value"
              >
                <label class="radio-border d-flex pa-2">
                  <v-radio
                    class="px-0"
                    :value="item.value"
                  ></v-radio>
                  <div class="d-flex flex-column">
                    <div class="text-medium">
                      {{ $t('common.pets') }}
                    </div>
                    <h1 class="text-medium --c-prm">
                      {{ item.use }}
                    </h1>
                  </div>
                </label>

              </v-col>
              <v-col class="col-2 px-0 d-flex flex-column justify-center">
                <span class="text-small text-center --c-white px-0 bg-pink rounded-4 py-1 mx-2">
                  {{ $t('reservationAndUsageHistory.mandatory') }}
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-radio-group>
      </div>
      <!--Pet lot format END-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'HasPet',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      radio: [
        { use: this.$t('booking.accompaniedBy'), value: true },
        { use: this.$t('booking.unaccompaniedBy'), value: false }
      ]
    }
  }
}
</script>
